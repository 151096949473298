import React from "react"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"

const Iframe = styled.iframe`
  border: none;
  width: 100%;
  height: 305px;

  ${breakpoint(`small`)`
     height: 152px;
  `}
`

export default function VerifiedReviews () {
  return <Iframe data-testid="verified_reviews" src={APP_CONFIG.verifiedReviews.iframeUrl} />
}

VerifiedReviews.propTypes = {}
